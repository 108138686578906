:root {
  --buider-max-height: 100vh;
  --gray-main-color: #404d5b;
}

.form-builder-custom {
    font-size: 14px;
    font-weight: 300;
    color: var(--gray-main-color);
    width: 100%;
    max-height: var(--buider-max-height);
  }
  .form-builder-custom .react-form-builder {
    max-height: var(--buider-max-height);
  }
  .form-builder-custom .react-form-builder > div {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 0 !important;
  }
  .form-builder-custom .react-form-builder .react-form-builder-toolbar,
  .form-builder-custom .react-form-builder .react-form-builder-preview {
    max-height: var(--buider-max-height);
    overflow-y: scroll;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative !important;
  }
  .form-builder-custom .react-form-builder .react-form-builder-preview {
    position: relative !important;
    width: 70% !important;
  }
  .form-builder-custom  .react-form-builder .react-form-builder-preview .custom-checkbox label{
    padding-left: .5em !important;
    padding-top: .5em !important;
  }
  .form-builder-custom .react-form-builder .react-form-builder-toolbar {
    width: 30% !important;
    margin-top: 0px;
  }
  .form-builder-custom .react-form-builder .react-form-builder-toolbar ul {
    list-style: none;
    padding-left: 0;
  }
  
  .form-builder-custom .react-form-builder .react-form-builder-toolbar ul li {
    padding: 16px 12px;
    border: none;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: grab;
  }