.add-lead{
    background-color: #343434 !important;
    color: #fff !important;
    position: absolute !important;
    margin-left: auto !important;
}
.add-lead:hover {
    background-color: #343434 !important;
    color: #fff !important;
}
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin: 0 auto;
}